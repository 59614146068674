@import 'styles/constants';
@import 'styles/fonts';

body {
  margin: 0;
}

a {
  text-decoration: none;
  color: $color-main;
  /* unvisited link */
  &:link {
    color: $color-main;
  }

  /* visited link */
  &:visited {
    color: $color-main;
  }

  /* mouse over link */
  &:hover {
    color: $color-accent;
  }

  /* selected link */
  &:active {
    color: $color-main;
  }
}

.header-logo {
  display: flex;
  align-items: center;
}

.logo {
  height: 3rem;
  margin: 0 1rem;
}

.wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-areas:
          "header header"
          "sidebar sidebar"
          "main main"
          "footer footer";
}

header {
  grid-area: header;
  border-bottom: 1px solid $color-main;
  padding: 5px 0;
}

main {
  grid-column: main;
}

aside {
  grid-row: sidebar;
}

footer {
  grid-column: footer;
  border-top: 1px solid $color-main;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
}

@media (min-width: 480px) {
  .wrapper {
    grid-template-areas:
            "header header"
            "sidebar main"
            "footer footer";
  }
}