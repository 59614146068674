@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');

html {
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
}
.title {
  font-weight: 700;
  font-size: 2rem;
}

.label {
  font-weight: 400;
  font-size: 0.75rem;
}

.sub-label {
  font-weight: 200;
  font-size: 1rem;
}